//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ReportIcon from '@mui/icons-material/Report';
import ListItem from '@mui/material/ListItem';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";
import { useImmer } from 'use-immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { AutocompleteWithAllOption } from "../../components/AutocompleteWithAllOption";
import { RichTextEditor, isEmptyDelta } from "../../components/RichTextEditor/RichTextEditor";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getElapsedTimeInDays, compareObjectKeyValuePairs } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, DialogContentText, Divider, FilledInput, FormGroup, FormHelperText, Input, InputBase, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import { Criterion, Observation } from "../../models/Referential";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

let titleLabel = "";

const ObservationDialog = ({ isOpen, chapterNumber, objectiveNumber, criterionNumber, observationNumber, mode, onClose, referential, dispatchReferential }) => {
    const { lists } = useUser();
    const { control, handleSubmit, reset, getValues, formState } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        if (!isOpen) return;
        reset();
        if (chapterNumber && objectiveNumber && criterionNumber && observationNumber) {
            titleLabel = (mode === "insert" ? "Insérer nouveau constat" : "Modifier constat") + ` N° ${chapterNumber}.${objectiveNumber}.${criterionNumber}.${observationNumber}`;
        }
    }, [isOpen])

    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit((data) => {
        //sanitize data before dispatch
        let formValues = { ...data, concerned_activities: data.concerned_activities.filter(e => e !== "Tout cocher") };
        dispatchReferential({
            type: "update_and_filter",
            update: draft => {
                if (mode === "insert") {
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations.splice(observationNumber - 1, 0, new Observation({ ...formValues, clientAction: "new" }));
                } else if (mode === "modify") {
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].question = formValues.question;
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].comment = formValues.comment;
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].requested_response = formValues.requested_response;
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].nb_responses = formValues.nb_responses;
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].concerned_activities = formValues.concerned_activities;
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].rbpp = formValues.rbpp;
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].group = formValues.group;
                }
                //we open the list so user can see his new or modified observation
                draft.chapters[chapterNumber - 1].isOpen = true;
                draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].isOpen = true;
                draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].isOpen = true;
            }
        })
        onClose();
    })

    return (
        <div>
            <Dialog
                open={isOpen}
                sx={{
                    '& .MuiDialog-paper': { // Target the Dialog's paper element
                        maxWidth: 'none', // Remove the default max width
                        width: '80%', // Set a custom width, can be in px, %, etc.
                    },
                }}
            >
                <DialogTitle>{titleLabel}</DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent>
                        <Stack direction="column" spacing={2}>
                            <Controller
                                name="question"
                                defaultValue={mode === "modify" ? referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].question || "" : ""}
                                rules={Criterion.rules.question}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                    const { onChange, value } = field;
                                    return (
                                        <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                            <TextField
                                                autoFocus
                                                label="Question"
                                                fullWidth
                                                variant="standard"
                                                multiline
                                                value={value}
                                                onChange={event => onChange(event?.target?.value)}
                                                error={error?.message ? true : false}
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                            <Controller
                                name="rbpp"
                                defaultValue={mode === "modify" ? referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].rbpp || "" : ""}
                                rules={Criterion.rules.rbpp}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                    const { onChange, value } = field;
                                    return (
                                        <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                            <span style={{ fontSize: "0.7rem", opacity: "0.7" }}>RBPP</span>
                                            <RichTextEditor
                                                value={(value?.delta && !isEmptyDelta(value.delta)) ? value.delta : value.html}
                                                onChange={(content, delta, source, editor) => {
                                                    let deltaValue = editor.getContents();
                                                    onChange({ html: content, delta: deltaValue });
                                                }}
                                                error={error?.message ? true : false}
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                            <Controller
                                name="requested_response"
                                rules={Observation.rules.requested_response}
                                defaultValue={mode === "modify" ?
                                    referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].requested_response || lists?.REPONSE_TYPE_LIST[0] :
                                    lists?.REPONSE_TYPE_LIST[0]}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                    const { onChange, value } = field;
                                    return (
                                        <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                            <InputLabel>Type de réponse</InputLabel>
                                            <Select
                                                value={value}
                                                onChange={event => onChange(event?.target?.value)}
                                                MenuProps={MenuProps}
                                                error={error?.message ? true : false}
                                            >
                                                {lists?.REPONSE_TYPE_LIST?.map(a => (
                                                    <MenuItem key={a} value={a}>{a}</MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                            <Controller
                                name="group"
                                rules={Observation.rules.group}
                                defaultValue={mode === "modify" ?
                                    referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].group || null :
                                    null}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                    const { onChange, value } = field;
                                    return (
                                        <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                            <InputLabel>Lieu</InputLabel>
                                            <Select
                                                value={value}
                                                onChange={event => onChange(event?.target?.value)}
                                                MenuProps={MenuProps}
                                                error={error?.message ? true : false}
                                            >
                                                {Object.keys(lists?.OBSERVATION_GROUP_LIST)?.map(a => {
                                                    return <MenuItem key={a} value={a}>{lists?.OBSERVATION_GROUP_LIST[a].label}</MenuItem>
                                                })}
                                            </Select>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                            <Controller
                                name="nb_responses"
                                rules={Observation.rules.nb_responses}
                                defaultValue={mode === "modify" ? referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].nb_responses || lists?.NB_RESPONSE_LIST[0] : lists?.NB_RESPONSE_LIST[0]}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                    const { onChange, value } = field;
                                    return (
                                        <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                            <InputLabel>Nb réponses</InputLabel>
                                            <Select
                                                value={value}
                                                onChange={event => onChange(event?.target?.value)}
                                                MenuProps={MenuProps}
                                                error={error?.message ? true : false}
                                            >
                                                {lists?.NB_RESPONSE_LIST.map(nb => (
                                                    <MenuItem key={nb} value={nb}>{nb}</MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                            <Controller
                                name={"concerned_activities"}
                                rules={Observation.rules.concerned_activities}
                                defaultValue={mode === "modify" ? referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].observations[observationNumber - 1].concerned_activities || [] : []}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                    const { onChange, value } = field;
                                    //init options for Autocomplete
                                    const activitiesOptions = lists?.ACTIVITY_LIST;
                                    return (
                                        <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                            <AutocompleteWithAllOption
                                                options={activitiesOptions}
                                                getOptionLabel={option => `${lists?.ACTIVITY_LIST[option]?.short}: ${lists?.ACTIVITY_LIST[option]?.label}`}
                                                value={value}
                                                onChange={onChange}
                                                label="Activité(s) concernée(s)"
                                            />
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCancel}>Annuler</Button>
                        <Button type="submit">Valider</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );
}

export { ObservationDialog };