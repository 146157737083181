//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ReportIcon from '@mui/icons-material/Report';
import ListItem from '@mui/material/ListItem';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";
import { useImmer } from 'use-immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { AutocompleteWithAllOption } from "../../components/AutocompleteWithAllOption";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getElapsedTimeInDays, compareObjectKeyValuePairs } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, DialogContentText, Divider, FilledInput, FormGroup, FormHelperText, Input, InputBase, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import { Criterion } from "../../models/Referential";


let titleLabel = "";

const CriterionDialog = ({ isOpen, chapterNumber, objectiveNumber, criterionNumber, mode, onClose, referential, dispatchReferential }) => {
    const { lists } = useUser();
    const { control, handleSubmit, reset, getValues, unregister, watch, formState } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        if (!isOpen) return;
        reset();
        if (chapterNumber && objectiveNumber && criterionNumber) {
            titleLabel = (mode === "insert" ? "Insérer nouveau critère" : "Modifier critère") + ` N° ${chapterNumber}.${objectiveNumber}.${criterionNumber}`;
        }
    }, [isOpen])

    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit((data) => {
        let formValues = { ...data };
        dispatchReferential({
            type: "update_and_filter",
            update: draft => {
                if (mode === "insert") {
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria.splice(criterionNumber - 1, 0, new Criterion({ ...formValues, clientAction: "new" }));
                } else if (mode === "modify") {
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].name = formValues.name;
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].important = formValues.important;
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].job_groups = formValues.job_groups;
                    draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].objectivePaqMatch = formValues.objectivePaqMatch;
                }
                //we open the list so user can see his new or modified criterion
                draft.chapters[chapterNumber - 1].isOpen = true;
                draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].isOpen = true;
            }
        })
        onClose();
    })

    return (
        <div>
            <Dialog
                open={isOpen}
                sx={{
                    '& .MuiDialog-paper': { // Target the Dialog's paper element
                        maxWidth: 'none', // Remove the default max width
                        width: '80%', // Set a custom width, can be in px, %, etc.
                    },
                }}
            >
                <DialogTitle>{titleLabel}</DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent>
                        <Controller
                            name="name"
                            defaultValue={mode === "modify" ? referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].name || "" : ""}
                            rules={Criterion.rules.name}
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                                const { onChange, value } = field;
                                return (
                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                        <TextField
                                            autoFocus
                                            label="Nom"
                                            fullWidth
                                            variant="standard"
                                            multiline
                                            value={value}
                                            onChange={event => onChange(event?.target?.value)}
                                            error={error?.message ? true : false}
                                        />
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                        <Controller
                            name="important"
                            rules={{}}
                            defaultValue={mode === "modify" ? referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].important || false : false}
                            control={control}
                            render={({ field }) => {
                                const { onChange, value } = field;
                                return (
                                    <FormControl sx={{ minWidth: 400 }} variant="standard">
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={event => onChange(event?.target?.checked)}
                                                />
                                            } label="Impératif" labelPlacement="end" />
                                        </FormGroup>
                                    </FormControl>
                                )
                            }}
                        />
                        <Controller
                            name="job_groups"
                            rules={Criterion.rules.job_groups}
                            defaultValue={mode === "modify" ? referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].job_groups || [] : []}
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                                const { onChange, value } = field;
                                return (
                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                        <AutocompleteWithAllOption
                                            options={lists?.JOB_GROUP_LIST}
                                            getOptionLabel={(option) => lists?.JOB_GROUP_LIST[option].label}
                                            value={value}
                                            onChange={onChange}
                                            label="Groupe(s) métier(s)"
                                        />
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                        <Controller
                            name="objectivePaqMatch"
                            rules={Criterion.rules.objectivePaqMatch}
                            defaultValue={mode === "modify" ? referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].criteria[criterionNumber - 1].objectivePaqMatch || [] : []}
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                                const { onChange, value } = field;
                                return (
                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                        <AutocompleteWithAllOption
                                            options={lists?.OBJECTIVE_PAQ_LIST}
                                            getOptionLabel={(option) => lists?.OBJECTIVE_PAQ_LIST[option].label}
                                            value={value}
                                            onChange={onChange}
                                            label="Lien(s) objectif(s) PAQ"
                                        />
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCancel}>Annuler</Button>
                        <Button type="submit">Valider</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );
}

export { CriterionDialog };