import { useImmer } from 'use-immer';
import { FormControl, MenuItem, Select,  ToggleButton, ToggleButtonGroup } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
/**************************************************************************************
 * Toast
 * @param {*} param0
 * @returns
 */
export function useDateFilter({mode=undefined}) {

    const [viewFilters, updateViewFilters] = useImmer({
        p_num: '15',
        p_type: 'm',
        r_sdate: null,
        r_stime: null,
        r_edate: null,
        r_etime: null,
        d_year: null,
        d_month: null,
        d_day: null,
        mode: mode || "0"
    });

    const addFilterValues = (searchParams) => {
        searchParams.mode = viewFilters.mode;

        if (viewFilters.mode === "0") {
            searchParams.p_num = viewFilters.p_num;
            searchParams.p_type = viewFilters.p_type;

        } else if (viewFilters.mode === "1") {
            searchParams.r_sdate = viewFilters.r_sdate;
            if (viewFilters.r_stime !== null) {
                searchParams.r_sdate.set({
                    hour: viewFilters.r_stime.get('hour'),
                    minute: viewFilters.r_stime.get('minute')
                });
            }
            searchParams.r_sdate = searchParams.r_sdate.format('YYYY-MM-DDTHH:mm:ss');

            if (viewFilters.r_edate !== null) {
                searchParams.r_edate = viewFilters.r_edate;
                if (viewFilters.r_etime !== null) {
                    searchParams.r_edate.set({
                        hour: viewFilters.r_etime.get('hour'),
                        minute: viewFilters.r_etime.get('minute')
                    });
                }
                searchParams.r_edate = searchParams.r_edate.format('YYYY-MM-DDTHH:mm:ss');
            }
        }else if(viewFilters.mode === "2"){
            if(viewFilters.d_year !== null){
                searchParams.d_year = viewFilters.d_year.format('YYYY-MM-DDTHH:mm:ss');
            }else if(viewFilters.d_month !== null){
                searchParams.d_month = viewFilters.d_month.format('YYYY-MM-DDTHH:mm:ss');
            }else if(viewFilters.d_day !== null){
                searchParams.d_day = viewFilters.d_day.format('YYYY-MM-DDTHH:mm:ss');
            }
            
        }
    }
    const DateFilter = () =>
    (
        <>
            <ToggleButtonGroup
                color="primary"
                value={viewFilters?.mode}
                exclusive
                onChange={(e) => {
                    updateViewFilters((d) => { d.mode = e.target.value });
                }}
                aria-label="Platform"
            >
                <ToggleButton value="0">Depuis</ToggleButton>
                <ToggleButton value="1">From-To</ToggleButton>
                <ToggleButton value="2">Date</ToggleButton>
            </ToggleButtonGroup>
            {viewFilters?.mode === "0" ?
                <>
                    <FormControl variant="standard" style={{ width: '80px', marginRight: '15px' }}>
                        <Select
                            displayEmpty
                            value={viewFilters?.p_num}
                            onChange={(event) => {
                                updateViewFilters(draft => {
                                    draft.p_num = event.target.value;
                                })
                            }}
                            id="filter_sm-visitors_period_1-select"
                        >
                            {Array.from({ length: 30 }, (_, i) => i + 1).map(s => (
                                <MenuItem value={s}>
                                    {s}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="standard" style={{ width: '90px', marginRight: '15px' }}>
                        <Select
                            displayEmpty
                            value={viewFilters?.p_type}
                            onChange={(event) => {
                                updateViewFilters(draft => {
                                    draft.p_type = event.target.value;
                                })
                            }}
                            id="filter_sm-visitors_period_2-select"
                        >

                            <MenuItem value="m">
                                Minutes
                            </MenuItem>
                            <MenuItem value="h">
                                Hours
                            </MenuItem>
                            <MenuItem value="d">
                                Days
                            </MenuItem>

                        </Select>
                    </FormControl>
                </>
                : viewFilters?.mode === "1" ?
                    <LocalizationProvider dateAdapter={AdapterMoment}>


                        <DatePicker label="From Date" size="small" disableFuture slotProps={{ textField: { size: 'small' } }}
                            maxDate={viewFilters?.r_edate}
                            value={viewFilters?.r_sdate}
                            onChange={(newValue) => {
                                // if(viewFilters.r_edate && newValue.isAfter(viewFilters.r_edate )){
                                //     alert('Fucktard');
                                //     return;
                                // }
                                updateViewFilters((d) => { d.r_sdate = newValue })
                            }}
                        />
                        <TimePicker label="From Time" ampm={false} slotProps={{ textField: { size: 'small' } }}
                            value={viewFilters?.r_stime}
                            onChange={(newValue) => {
                                updateViewFilters((d) => { d.r_stime = newValue })
                            }}
                        />
                        <DatePicker label="To Date" size="small" disableFuture slotProps={{ textField: { size: 'small' } }}
                            minDate={viewFilters?.r_sdate}
                            value={viewFilters?.r_edate}
                            onChange={(newValue, e) => {
                                updateViewFilters((d) => { d.r_edate = newValue })
                            }}
                        />
                        <TimePicker label="To Time" ampm={false} slotProps={{ textField: { size: 'small' } }}
                            value={viewFilters?.r_etime}
                            onChange={(newValue) => {
                                updateViewFilters((d) => { d.r_etime = newValue })
                            }}
                        />
                    </LocalizationProvider>
                    :
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            slotProps={{ textField: { size: 'small' } }}
                            label={'"year"'}
                            disableFuture
                            value={viewFilters?.d_year}
                            openTo="year"
                            views={['year']}
                            onChange={(newValue) => {
                                updateViewFilters((d) => {
                                    d.d_year = newValue;
                                    d.d_month = null;
                                    d.d_day = null;
                                })
                            }}
                        />
                        or
                        <DatePicker
                            label={'"month"'}
                            disableFuture
                            value={viewFilters?.d_month}
                            slotProps={{ textField: { size: 'small' } }}
                            openTo="month"
                            views={['year', 'month']}
                            onChange={(newValue) => {
                                updateViewFilters((d) => {
                                    d.d_year = null;
                                    d.d_month = newValue;
                                    d.d_day = null;
                                })
                            }}
                        />
                        or
                        <DatePicker
                            label={'"day"'}
                            disableFuture
                            value={viewFilters?.d_day}
                            slotProps={{ textField: { size: 'small' } }}
                            openTo="day"
                            views={['year', 'month', 'day']}
                            onChange={(newValue) => {
                                updateViewFilters((d) => {
                                    d.d_year = null;
                                    d.d_month = null;
                                    d.d_day = newValue;
                                })
                            }}
                        />
                    </LocalizationProvider>
            }</>
    );

    return {
        DateFilter,
        addFilterValues
    }
}
