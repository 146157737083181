//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ReportIcon from '@mui/icons-material/Report';
import ListItem from '@mui/material/ListItem';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";
import { useImmer } from 'use-immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getElapsedTimeInDays, compareObjectKeyValuePairs } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, DialogContentText, Divider, FilledInput, FormHelperText, Input, InputBase, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import { Objective } from "../../models/Referential";

let titleLabel = "";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ObjectiveDialog = ({ isOpen, chapterNumber, objectiveNumber, mode, onClose, referential, dispatchReferential }) => {
    const { control, handleSubmit, reset, getValues, unregister, watch, formState } = useForm({
        mode: "onChange"
    });

    const { lists } = useUser();

    useEffect(() => {
        if (!isOpen) return;
        reset();
        if (chapterNumber && objectiveNumber) {
            titleLabel = (mode === "insert" ? "Insérer nouvel objectif" : "Modifier objectif") + ` N° ${chapterNumber}.${objectiveNumber}`;
        }
    }, [isOpen])

    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit((data) => {
        let formValues = { ...data };
        dispatchReferential({
            type: "update_and_filter",
            update: draft => {
                if (mode === "insert") {
                    draft.chapters[chapterNumber - 1].objectives.splice(objectiveNumber - 1, 0, new Objective({ ...formValues, clientAction: "new" }));
                } else if (mode === "modify") {
                    {
                        draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].name = formValues.name;
                        draft.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].thematic = formValues.thematic;
                    }
                }
                //we open the list so user can see his new or modified criterion
                draft.chapters[chapterNumber - 1].isOpen = true;
            }
        });
        onClose();
    })

    return (
        <div>
            <Dialog
                open={isOpen}
            >
                <DialogTitle>{titleLabel}</DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent>
                        <Controller
                            name="name"
                            defaultValue={mode === "modify" ? referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].name : ""}
                            rules={Objective.rules.name}
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                                const { onChange, value } = field;
                                return (
                                    <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                        <TextField
                                            autoFocus
                                            label="Nom"
                                            fullWidth
                                            variant="standard"
                                            multiline
                                            value={value}
                                            onChange={event => onChange(event?.target?.value)}
                                            error={error?.message ? true : false}
                                        />
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                        <Controller
                            name="thematic"
                            rules={Objective.rules.thematic}
                            defaultValue={mode === "modify" ?
                                referential.chapters[chapterNumber - 1].objectives[objectiveNumber - 1].thematic || null :
                                null}
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                                const { onChange, value } = field;
                                return (
                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                        <InputLabel>Thématique</InputLabel>
                                        <Select
                                            value={value}
                                            onChange={event => onChange(event?.target?.value)}
                                            MenuProps={MenuProps}
                                            error={error?.message ? true : false}
                                        >
                                            {Object.keys(lists?.THEMATIC_LIST)?.map(a => {
                                                return <MenuItem key={a} value={a}>{lists?.THEMATIC_LIST[a].label}</MenuItem>
                                            })}
                                        </Select>
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                )
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCancel}>Annuler</Button>
                        <Button type="submit">Valider</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );
}

export { ObjectiveDialog };
