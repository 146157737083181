//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ExtensionIcon from '@mui/icons-material/Extension';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { usePaths } from "../../context/PathsProvider";
import { useHttp } from "../../context/HttpProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { EstablishmentsDialog } from "./EstablishmentsDialog";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";
import { User, Role } from "../../models/User";
import Establishment from "../../models/Establishment";


//show list of all users that have any permission in the selected establishment, should it be validated or not

const Establishments = () => {

    const { httpRequest } = useHttp();
    const { lists, user, customer, fetchEstablishments, establishments, setSelectedEstablishment } = useUser();
    const theme = useTheme();
    const { paths } = usePaths();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const emptyEstablishmentsDialogState = { isOpen: false, title: "", establishment: undefined };
    const [establishmentsDialogState, setEstablishmentsDialogState] = useState(emptyEstablishmentsDialogState);


    const getUnlockedModulesString = (modules) => {
        if (!modules || modules.length === 0) return "";
        let string = "";
        for (let [index, module] of modules.entries()) {
            let elTmp = lists.LICENSE_MODULES_LIST.find(e => e.value === module);
            string += elTmp.short + (index === modules.length - 1 ? "" : ", ");
        }
        return string;
    }

    const columns = useMemo(() => [
        {
            accessorKey: 'name',
            header: 'Nom',
            enableGrouping: true,
            enableColumnFilterModes: false,
        },
        {
            id: 'activity',
            accessorFn: (row) => lists?.ACTIVITY_LIST[row?.activity]?.short,
            header: 'Activité',
            enableGrouping: true,
            enableColumnFilterModes: false,
        },
        {
            id: 'area',
            accessorFn: (row) => `${row?.address?.zipcode?.slice(0, 2)} - ${row?.address?.city}`,
            header: 'Emplacement',
            enableGrouping: true,
            enableColumnFilterModes: false,
        },
        {
            id: 'unlocked_modules',
            header: 'Modules débloqués',
            accessorFn: (row) => getUnlockedModulesString(row?.unlocked_modules),
            enableGrouping: true,
            enableColumnFilterModes: false,
        },
    ], [establishments, lists]);


    return (
        <>
            <Box sx={{ width: '100%' }}>
                <MaterialReactTable
                    columns={columns}
                    data={establishments ? establishments : []}
                    enableColumnOrdering
                    enableGlobalFilter //Material React Table global filter will only work if empty string instead of undefined
                    enableGrouping
                    enableEditing
                    enablePinning
                    enableColumnResizing
                    enableColumnFilterModes
                    editingMode="modal"
                    initialState={{ density: 'comfortable', showColumnFilters: true, columnPinning: { right: ["mrt-row-actions"] } }}
                    state={{ showSkeletons: establishments === undefined }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Interactions',
                            size: 120,
                            Cell: ({ row }) => (
                                <>
                                    <IconButton
                                        onClick={() => setEstablishmentsDialogState({
                                            isOpen: true,
                                            establishment: row?.original,
                                            title: `Modifier établissement ${row?.original?.name}`
                                        })}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </>
                            )
                        }
                    }}
                    muiTableBodyCellProps={{ align: "center" }}
                    renderEmptyRowsFallback={() => {
                        return <div style={{ width: "200px", textAlign: "center", position: "fixed", left: "calc(50vw - 100px)" }}><h3><em>{establishments?.length > 0 ? 'Aucun résultat.' : 'Aucun établissement.'}</em></h3></div>
                    }}
                    // enableDensityToggle={false}
                    localization={MRT_Localization_FR}
                    enablePagination={false}
                    enableRowVirtualization
                    rowVirtualizerProps={{
                        overscan: 5, //adjust the number or rows that are rendered above and below the visible area of the table
                        estimateSize: () => establishments?.length, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
                    }}
                    renderBottomToolbarCustomActions={({ table, row }) => {
                        let selectedRowModel = table.getSelectedRowModel();
                        return (
                            <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "end", gap: 2 }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setEstablishmentsDialogState({
                                        isOpen: true,
                                        establishment: new Establishment({ clientAction: "new", customer_id: customer._id, customer_hid: customer.human_id }),
                                        title: `Nouvel établissement`
                                    })}
                                >NOUVEL ÉTABLISSEMENT</Button>
                            </Box>
                        )
                    }}
                    muiTableContainerProps={({ table }) => ({
                        sx: {
                            maxHeight: `calc(100vh - ${theme.appbarHeight} - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
                        }
                    })}
                />
            </Box>
            <EstablishmentsDialog
                isOpen={establishmentsDialogState.isOpen}
                onClose={async () => {
                    setEstablishmentsDialogState(emptyEstablishmentsDialogState);
                    fetchEstablishments();
                }}
                title={establishmentsDialogState.title}
                establishment={establishmentsDialogState.establishment}
            />
        </>
    );
};

export default Establishments;