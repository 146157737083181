//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { Controller, useForm } from "react-hook-form";
import { useImmer } from 'use-immer';

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../../context/UserProvider";
import { useHttp } from "../../context/HttpProvider";
import { useToast } from "../../context/ToastProvider";
import { useDialog } from "../../context/DialogProvider";
import { usePaths } from "../../context/PathsProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { Accordion, AccordionSummary } from "../../components/Accordion";
import { AutocompleteWithAllOption } from "../../components/AutocompleteWithAllOption";
// import { TextModal } from './TextModal';
// import { CheckboxModal } from './CheckboxModal';
// import { DateModal } from './DateModal';

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import { getServerUrl } from "../../config";
import { compareObjects } from '../../lib/lib';
//*********************************************************************************************************************************************//
//                                                               MODELS imports                                                                //
//*********************************************************************************************************************************************//
import { AccordionDetails, Autocomplete, Checkbox, Chip, Divider, FilledInput, FormHelperText, Input, InputBase, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Stack } from "@mui/material";
import { User } from "../../models/User";



const CustomerUsersDialog = ({ isOpen, onClose, title, user }) => {
    const theme = useTheme();
    const { lists } = useUser();
    const { httpRequest } = useHttp();
    const { presentToast } = useToast();
    const { presentAlertDialog } = useDialog();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customer_hid = params.get('customer_hid');

    const { control, handleSubmit, reset, setError, setValue, formState } = useForm({
        mode: "all"
    });


    useEffect(() => {
        reset();
    }, [user])

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleClickCancel = () => {
        onClose();
    }

    const handleClickValidate = handleSubmit(async (data) => {
        console.log(data);
        let formValues = { ...data };

        //filter selectAll value in job_groups
        formValues = {
            ...formValues,
            job_groups: { value: formValues?.job_groups?.value?.filter(e => e !== "Tout cocher") },
        };
        //copy user then override all the key values of user with the ones from data
        let userTmp = new User({ ...user, ...formValues });
        //now handle permissions with mandatory role from current establishment
        let response = undefined;
        if (user?.clientAction === "new") {
            response = await httpRequest({
                url: `${getServerUrl()}/admin/customer/${customer_hid}/user/create`,
                data: { user: userTmp },
                method: "post",
                headers: { "Content-type": "Application/json" },
                withCredentials: true
            }, true, true);
        } else {
            let hasChanged = !compareObjects({ obj1: new User(user), obj2: new User(userTmp), falsyEquals: true, includeObj2Keys: true, keysToIgnore: ["clientAction", "password.is_temporary"] });
            if (hasChanged) {
                response = await httpRequest({
                    url: `${getServerUrl()}/admin/customer/${customer_hid}/user/update`,
                    data: { user: userTmp },
                    method: "post",
                    headers: { "Content-type": "Application/json" },
                    withCredentials: true
                }, true, true);
            } else {
                presentToast({
                    severity: "warning",
                    message: `${title}: aucun champ modifié`
                });
            }
        }
        if (response?.status === 200) {
            presentToast({
                severity: "success",
                message: `${title}: succès`
            })
            if (user?.clientAction === "new") {
                let jsxMessage = undefined;
                if (response.data.email === "admin") {
                    jsxMessage = (
                        <div><p>Utilisateur créé avec succès.</p>
                            <p>Voici l'identifiant et le mot de passe à communiquer à votre utilisateur. Un email vous a été envoyé avec ces informations.</p><br />
                            Identifiant: <strong>{response.data.username}</strong><br />
                            Mot de passe provisoire: <strong>{response.data.password}</strong><br />
                            <p>N'oubliez pas maintenant de lui créer les permissions nécessaires à son utilisation !</p>
                        </div>
                    );
                } else if (response.data.email === "user") {
                    jsxMessage = (
                        <div>
                            <p>Un email a été envoyé à l'utilisateur que vous venez de créer avec ses identifiants de connexion.</p>
                            <p>N'oubliez pas maintenant de lui créer les permissions nécessaires à son utilisation !</p>
                        </div>
                    );
                }
                presentAlertDialog({
                    message: jsxMessage,
                    buttons: [{
                        text: "OK",
                        handler: () => {
                            onClose();
                        }
                    }]
                })
            }
            onClose();
        }
        if (response?.status === 400 && response?.data?.formDetails?.length > 0) {
            for (let fd of response.data.formDetails) {
                setError(fd.field, {
                    type: "manual",
                    message: fd.errorMessage
                });
                setValue(fd.field, fd.fieldValue);
            }
        }
    })


    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <Box
                    component="form"
                    onSubmit={handleClickValidate}
                >
                    <DialogContent sx={{ maxHeight: "calc(100vh - 190px)", overFlowY: "auto" }}>
                        <Accordion defaultExpanded >
                            <AccordionSummary>
                                <Typography>Informations utilisateur</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="column" spacing={2}>
                                    <Controller
                                        name="firstname"
                                        rules={{ required: "Prénom requis" }}
                                        defaultValue={user?.firstname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Prénom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="lastname"
                                        rules={{ required: "Nom requis" }}
                                        defaultValue={user?.lastname || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Nom"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"type.value"}
                                        rules={{ required: "Type utilisateur requis" }}
                                        defaultValue={user?.type?.value || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={lists?.USER_TYPES_LIST}
                                                        value={lists.USER_TYPES_LIST.find(e => e.value === value) || null}
                                                        isOptionEqualToValue={(option, value) => {
                                                            return option?.value === value.value
                                                        }}
                                                        getOptionLabel={option => {
                                                            return option.label;
                                                        }}
                                                        onChange={(event, selectedOption) => {
                                                            onChange(selectedOption?.value || null)
                                                        }}
                                                        renderInput={(params) => {
                                                            return <TextField {...params} label="Type utilisateur" variant="standard" inputRef={ref} />
                                                        }}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    {(user?.clientAction === "modify") &&
                                        <Controller
                                            name={"status.value"}
                                            rules={{}}
                                            defaultValue={user?.status?.value || null}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                        <Autocomplete
                                                            options={lists?.USER_STATUSES_LIST}
                                                            value={lists.USER_STATUSES_LIST.find(e => e.value === value) || null}
                                                            isOptionEqualToValue={(option, value) => {
                                                                return option?.value === value.value
                                                            }}
                                                            getOptionLabel={option => {
                                                                return option.label;
                                                            }}
                                                            onChange={(event, selectedOption) => onChange(selectedOption?.value || null)}
                                                            renderInput={(params) => {
                                                                return <TextField {...params} label="Statut utilisateur" variant="standard" inputRef={ref} />
                                                            }}
                                                        />
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    }
                                    <Controller
                                        name="email.value"
                                        rules={{
                                            pattern: {
                                                value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                                                message: "Adresse email invalide"
                                            }
                                        }}
                                        defaultValue={user?.email?.value || ''}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                    <TextField
                                                        label="Email"
                                                        variant="standard"
                                                        value={value}
                                                        onChange={event => onChange(event?.target?.value)}
                                                        inputRef={ref}
                                                        error={error?.message ? true : false}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"job"}
                                        rules={{ required: "Poste requis" }}
                                        defaultValue={user?.job || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={Object.keys(lists?.JOB_LIST)}
                                                        value={value}
                                                        getOptionLabel={(option) => lists?.JOB_LIST[option]?.label || ""}
                                                        onChange={(event, selectedOption) => onChange(selectedOption)}
                                                        renderInput={(params) => {
                                                            return <TextField {...params} label="Poste" variant="standard" inputRef={ref} />
                                                        }}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name="job_groups.value"
                                        rules={{ required: "Groupe(s) métier(s) requis" }}
                                        defaultValue={user?.job_groups?.value || []}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <AutocompleteWithAllOption
                                                        options={lists?.JOB_GROUP_LIST}
                                                        getOptionLabel={(option) => lists?.JOB_GROUP_LIST[option].label}
                                                        value={value}
                                                        onChange={onChange}
                                                        ref={ref}
                                                        label="Groupe(s) métier(s)"
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    <Controller
                                        name={"organization"}
                                        rules={{}}
                                        defaultValue={user?.organization || null}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { onChange, value, ref } = field;
                                            return (
                                                <FormControl sx={{ minWidth: 400 }} fullWidth variant="standard" error={error?.message ? true : false}>
                                                    <Autocomplete
                                                        options={Object.keys(lists.ORGANIZATION_LIST)}
                                                        value={value}
                                                        getOptionLabel={option => {
                                                            return lists.ORGANIZATION_LIST?.[option]?.label;
                                                        }}
                                                        onChange={(event, selectedOption) => onChange(selectedOption)}
                                                        renderInput={(params) => {
                                                            return <TextField {...params} label="Organisme" variant="standard" inputRef={ref} />
                                                        }}
                                                    />
                                                    <FormHelperText>{error?.message}</FormHelperText>
                                                </FormControl>
                                            )
                                        }}
                                    />
                                    {(user?.clientAction === "modify") &&
                                        <Controller
                                            name="username"
                                            defaultValue={user?.username || ""}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                        <TextField
                                                            label="Nom d'utilisateur"
                                                            variant="standard"
                                                            value={value}
                                                            disabled={true}
                                                            onChange={event => onChange(event?.target?.value)}
                                                            inputRef={ref}
                                                            error={error?.message ? true : false}
                                                        />
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    }
                                    {(user?.password?.is_temporary) &&
                                        <Controller
                                            name="password.clear"
                                            defaultValue={user?.password?.clear || ""}
                                            rules={{
                                                required: "Mot de passe requis",
                                                minLength: { value: 8, message: "8 caractères minimum" }
                                            }}
                                            control={control}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <FormControl sx={{ minWidth: 400 }} variant="standard" error={error?.message ? true : false}>
                                                        <TextField
                                                            label="Mot de passe"
                                                            variant="standard"
                                                            value={value}
                                                            disabled={true}
                                                            onChange={event => onChange(event?.target?.value)}
                                                            inputRef={ref}
                                                            error={error?.message ? true : false}
                                                        />
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </FormControl>
                                                )
                                            }}
                                        />
                                    }
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClickCancel}
                        >
                            annuler
                        </Button>
                        <Button
                            autoFocus
                            type="submit"
                        >
                            valider
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    )
}

export { CustomerUsersDialog }